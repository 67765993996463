import React from "react";

const Home = () => {
    return (
        <>
            <h1 className="mt-3">Hello every one👋</h1>
            <p className="mt-3">Welcome to the home page of this simple web application. This application is built using React and Bootstrap.</p>
            <p className="mt-3">The website is developing by Jtidd</p>

            <h2 className="mt-3">Features</h2>
            <ul>
                <li>Convert Image: Convert an image to a different format.</li>
                <li>CSS Tools: Generate CSS code for various CSS properties.</li>
                <li>There's more...</li>
            </ul>
        </>
    );
};

export default Home;
