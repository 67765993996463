import React, { useState } from "react";

import Header from "./components/Header";
import Nav from "./components/Navbar";

import Home from "./components/Home";

import ConvertImage from "./components/Tools/ConvertImage";
import CssTools from "./components/Tools/CssTools";

import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function App() {
    const [page, setPage] = useState("home");

    const showTools = (data) => {
        console.log(data);
        setPage(data);
    };

    return (
        <div className="App">
            <Header />
            <div className="body container-1">
                <div className="row">
                    <div className="col-3">
                        <Nav showTools={showTools} />
                    </div>
                    <div className="col-9">
                        {page === "home" && (
                            <div className="component-container">
                                <Home />
                            </div>
                        )}
                        {page === "convert-image" && (
                            <div className="component-container">
                                <ConvertImage />
                            </div>
                        )}
                        {page === "css-tools" && (
                            <div className="component-container">
                                <CssTools />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
