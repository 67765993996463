import React, { useState } from "react";

import NavData from "./data";

import "./styles.css";

const Nav = (props) => {
    const [active, setActive] = useState(null);

    const showTools = (e) => {
        props.showTools(e.target.dataset.slug);
        setActive(e.target.dataset.slug);
    };

    return (
        <>
            <ul className="custom-navbar list-group border-end">
                {NavData.map((item, index) => {
                    return (
                        <li key={index} className="list-group-item" active={active === item.slug ? "true" : "false"}>
                            <span data-slug={item.slug} className="nav-link" onClick={showTools}>
                                {item.title}
                            </span>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default Nav;
