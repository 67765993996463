import React from "react";

import logo from "./images/logo-devtools.png";

import "./styles.css";

const Header = () => {
    return (
        <>
            <nav className="custom-header navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand d-flex" href="/">
                        <img src={logo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top" />
                        <span className="ms-2">DevTools</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <a className="nav-link active" aria-current="page" href="https://github.com/" target="_blank" rel="noreferrer">
                            Github
                        </a>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
