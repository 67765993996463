const data = [
    {
        title: "Convert Image",
        slug: "convert-image",
    },
    {
        title: "CSS Tools",
        slug: "css-tools",
    },
];

export default data;
