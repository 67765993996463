import React, { useRef, useState } from "react";

import "./styles.css";

const ConvertImage = () => {
    const fileInputRef = useRef(null);
    const [originalFileType, setOriginalFileType] = useState(null);
    const [convertToType, setConvertToType] = useState(null);
    const [convertedImage, setConvertedImage] = useState(null);
    const [convertedFileName, setConvertedFileName] = useState(null);
    const [error, setError] = useState(null);

    // Set title of the page
    document.title = "Convert Image - Tools";

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type.split("/")[1];
            setOriginalFileType(fileType);
            setConvertToType(null);
            setConvertedImage(null);
            setConvertedFileName(null);
            setError(null);
        }
    };

    const handleConvertToChange = (event) => {
        setConvertToType(event.target.value);
    };

    const handleConvertClick = async () => {
        if (!originalFileType) {
            setError("Please select a file first.");
            return;
        }
        if (!convertToType) {
            setError("Please select a conversion type.");
            return;
        }
        if (originalFileType === convertToType) {
            setError("Please select a different conversion type.");
            return;
        }

        try {
            const reader = new FileReader();
            reader.onload = async () => {
                const convertedImageBlob = await convertImageToType(reader.result, convertToType);
                const newImg = URL.createObjectURL(convertedImageBlob);
                const oldName = fileInputRef.current.files[0].name;
                setConvertedImage(newImg);
                setConvertedFileName(`${oldName.split(".")[0]}-converted.${convertToType}`);
                setError(null);
            };
            reader.onerror = () => {
                setError("Failed to read the image file.");
            };
            reader.readAsDataURL(fileInputRef.current.files[0]);
        } catch (error) {
            setError("An error occurred during image conversion.");
        }
    };

    const convertImageToType = async (imageData, targetType) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            resolve(blob);
                        } else {
                            reject("Conversion failed.");
                        }
                    },
                    `image/${targetType}`,
                    1 // Quality parameter from 0 to 1
                );
            };
            img.onerror = () => reject("Failed to load image.");
            img.src = imageData;
        });
    };

    return (
        <>
            <h1 className="mb-3 mt-3">Convert Image</h1>
            <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" />
            <br />
            {originalFileType && (
                <div>
                    <label className="mt-2">Select conversion type:</label>
                    <select value={convertToType} onChange={handleConvertToChange}>
                        <option value="">Select</option>
                        {originalFileType !== "jpeg" && <option value="jpeg">JPEG</option>}
                        {originalFileType !== "gif" && <option value="gif">GIF</option>}
                        {originalFileType !== "bmp" && <option value="bmp">BMP</option>}
                        {originalFileType !== "webp" && <option value="webp">WEBP</option>}
                        {originalFileType !== "png" && <option value="png">PNG</option>}
                        {/* Add more options as needed */}
                    </select>
                </div>
            )}
            <br />
            <button type="button" className="btn btn-primary" onClick={handleConvertClick}>
                Convert Image
            </button>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {convertedImage && (
                <div>
                    <h3 className="mt-5">Converted Image to {convertToType.toUpperCase()}</h3>
                    <div className="mt-2 mb-2">
                        <a href={convertedImage} download={convertedFileName}>
                            Download Image
                        </a>
                    </div>
                    <img src={convertedImage} alt="Converted" style={{ maxWidth: "300px", height: "auto" }} />
                </div>
            )}
        </>
    );
};

export default ConvertImage;
